var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"40em"},on:{"click:outside":function($event){_vm.ativo=false},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.ativo = false}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":_vm.veiculo.destinatario_oferta === 1 ? 'success' : 'info',"rounded":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s({ 1: 'Clientes', 2: 'Revendas' }[_vm.veiculo.destinatario_oferta])+" ")])]}}]),model:{value:(_vm.ativo),callback:function ($$v) {_vm.ativo=$$v},expression:"ativo"}},[_c('v-card',[_c('v-card-title',{staticClass:"justify-center"},[_c('h1',[_vm._v("ANUNCIO DE VEÍCULO")])]),_c('v-divider'),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.alerta),expression:"alerta"}],staticClass:"mt-4 mb-1 pa-2",attrs:{"type":_vm.tipo,"value":true}},[_c('h4',{attrs:{"justify-center":""}},[_vm._v(_vm._s(_vm.alerta))])]),_c('v-card-text',[_c('h3',{staticClass:"mt-4 mb-4 success--text"},[_vm._v(" Esse o destino do anúncio desse veículo é para "+_vm._s(_vm.destino === 2 ? 'empresa revendedoras ou concessionárias' : 'clientes ou usúarios')+". ")]),_c('v-flex',{staticClass:"mt-4",attrs:{"xs12":""}},[_c('v-select',{attrs:{"label":"Trocar destino de anúncio","outlined":"","items":[
            { text: 'clientes ou usúarios', value: 1 },
            { text: 'Revendedoras ou concessionárias', value: 2 } ]},model:{value:(_vm.destino),callback:function ($$v) {_vm.destino=$$v},expression:"destino"}})],1),(_vm.destino === 2)?_c('h2',{staticClass:"text-center",staticStyle:{"margin-bottom":"2em"}},[_vm._v(" SELECIONE UMA OPÇÃO ")]):_vm._e(),(_vm.destino === 2)?_c('v-layout',{staticClass:"mt-4 mb-4",staticStyle:{"width":"100%"},attrs:{"justify-space-around":"","wrap":""}},[_c('v-flex',{attrs:{"xs6":""}},[_c('v-btn',{class:_vm.todas ?'white--text' : '',attrs:{"outlined":"","block":"","text":!_vm.todas,"color":"info"},on:{"click":function($event){_vm.todas = true}}},[_vm._v(" Todas as empresas ")])],1),_c('v-flex',{attrs:{"xs6":""}},[_c('v-btn',{class:!_vm.todas ?'white--text' : '',attrs:{"outlined":"","block":"","text":_vm.todas,"color":"info"},on:{"click":function($event){_vm.todas = false}}},[_vm._v(" Empresas selecionadas ")])],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-slide-x-transition',[(_vm.destino == 2 && !_vm.todas)?_c('v-layout',{attrs:{"column":""}},[_c('v-layout',{staticClass:"mr-2 mb-4",attrs:{"justify-end":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"white--text",attrs:{"fab":"","small":"","color":"info"},on:{"click":function($event){_vm.filtroAtivo = !_vm.filtroAtivo}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.filtroAtivo ? 'filter_list_off' : 'filter_list'))])],1)]}}],null,false,847033873)},[_c('span',[_vm._v(" "+_vm._s(_vm.filtroAtivo ? 'Esconder filtro' : 'Mostrar filtro')+" ")])])],1),_c('v-slide-x-transition',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.filtroAtivo),expression:"filtroAtivo"}],staticClass:"ma-2"},[_c('v-card-actions',{staticClass:"justify-center d-inline-flex"},[_c('v-flex',{attrs:{"xs4":""}},[_c('v-text-field',{attrs:{"label":"Nome","color":"info"},model:{value:(_vm.nome_fantasia),callback:function ($$v) {_vm.nome_fantasia=$$v},expression:"nome_fantasia"}})],1),_c('v-flex',{attrs:{"xs3":""}},[_c('v-text-field',{attrs:{"label":"Cidade","color":"info"},model:{value:(_vm.cidade),callback:function ($$v) {_vm.cidade=$$v},expression:"cidade"}})],1),_c('v-flex',{attrs:{"xs3":""}},[_c('v-select',{attrs:{"label":"Estado","color":"info","items":_vm.estados,"clearable":""},model:{value:(_vm.estado),callback:function ($$v) {_vm.estado=$$v},expression:"estado"}})],1),_c('v-flex',{attrs:{"xs1":""}},[_c('v-btn',{staticClass:"white--text",attrs:{"fab":"","color":"info","small":""},on:{"click":function($event){return _vm.pegarRevendedoras(true)}}},[_c('v-icon',[_vm._v("search")])],1)],1)],1)],1)],1),_c('v-list',_vm._l((_vm.empresas),function(empresa,index){return _c('v-list-item',{key:index,staticClass:"ma-1",style:(_vm.isSelected(empresa) ? {'border': '1px solid #2196f3'} : {}),on:{"click":function () {
                    var temp = _vm.isSelected(empresa)
                    if (temp) { _vm.removerEmpresa(empresa) }
                    else { _vm.adicionarEmpresa(empresa) }
                  }}},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v("business")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(empresa.nome_fantasia)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(empresa.cnpj)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v("map")]),(empresa.endereco)?_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(empresa.endereco.cidade)+"/"+_vm._s(empresa.endereco.estado))]):_vm._e()],1)],1)],1)}),1),_c('div',{staticClass:"text-xs-center"},[_c('v-pagination',{attrs:{"length":_vm.pages,"circle":"","color":"info"},model:{value:(_vm.pagination_empresas.page),callback:function ($$v) {_vm.$set(_vm.pagination_empresas, "page", $$v)},expression:"pagination_empresas.page"}})],1)],1):_vm._e()],1)],1)],1):_vm._e()],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-space-around"},[_c('v-flex',[_c('v-btn',{attrs:{"block":"","text":""},on:{"click":function($event){_vm.ativo = false}}},[_vm._v(" Fechar ")])],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-flex',{attrs:{"xs6":""}},[_c('v-btn',{attrs:{"block":"","color":"success","text":"","loading":_vm.salvando},on:{"click":_vm.salvar}},[_vm._v(" Salvar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }